.first-else[data-v-0f5b020a] {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-family: KaiTi;
}
.first-else h1[data-v-0f5b020a] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: xx-large;
    font-weight: 900;
    font-family: KaiTi;
    color: #2babff;
    text-align: center;
    line-height: 1.5em;
}
.first-else .top[data-v-0f5b020a] {
    width: 100%;
    height: 34%;
    margin-top: 8%;
    position: relative;
}
.first-else .top h1[data-v-0f5b020a] {
      line-height: 3em;
}
.first-else .top ul[data-v-0f5b020a] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      padding-top: 4em;
}
.first-else .top ul li[data-v-0f5b020a] {
        width: 48%;
        height: 2em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.first-else .top ul li .peopleType[data-v-0f5b020a] {
          padding-left: 1em;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          width: 50%;
}
.first-else .top ul li .peopleNum[data-v-0f5b020a] {
          width: 50%;
          padding-left: 2em;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
.first-else .middle[data-v-0f5b020a] {
    width: 100%;
    height: 29%;
    position: relative;
}
.first-else .middle h1[data-v-0f5b020a] {
      line-height: 3em;
}
.first-else .middle .list[data-v-0f5b020a] {
      padding-top: 4em;
      height: 3em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.first-else .middle .list .box[data-v-0f5b020a] {
        text-align: center;
        line-height: 3em;
        width: 30%;
        height: 100%;
}
.first-else .bottom[data-v-0f5b020a] {
    width: 100%;
    height: 23%;
    position: relative;
}
.first-else .bottom .data[data-v-0f5b020a] {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.first-else .bottom .data .item[data-v-0f5b020a] {
        width: 45%;
        height: 3em;
}
.first-else .bottom .data .item .box[data-v-0f5b020a] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
.first-else .bottom .data .item .box p[data-v-0f5b020a] {
            height: 3em;
            line-height: 3em;
            text-align: center;
}
.first-else .bottom .data .item .box .name[data-v-0f5b020a] {
            width: 70%;
}
.first-else .bottom .data .item .box .value[data-v-0f5b020a] {
            width: 30%;
            text-align: left;
}
