.third-else[data-v-4535ea24] {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 10%;
}
.third-else h1[data-v-4535ea24] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: xx-large;
    font-weight: 900;
    font-family: KaiTi;
    color: #2babff;
    text-align: center;
    line-height: 1.5em;
}
.third-else section[data-v-4535ea24] {
    width: 100%;
    height: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}
.third-else section .left[data-v-4535ea24] {
      width: 50%;
      height: 90%;
}
.third-else section .left h1[data-v-4535ea24] {
        font-size: x-large;
        line-height: 2em;
}
.third-else section .left .data[data-v-4535ea24] {
        padding-top: 3em;
}
.third-else section .left .data .item[data-v-4535ea24] {
          width: 80%;
          margin: 2em auto;
}
.third-else section .left .data .item .box[data-v-4535ea24] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
}
.third-else section .left .data .item .box p[data-v-4535ea24] {
              width: 50%;
              text-align: center;
              line-height: 3em;
              height: 3em;
              overflow: hidden;
              text-overflow: ellipsis;
}
.third-else section .right[data-v-4535ea24] {
      width: 50%;
      height: 90%;
      position: relative;
}
.third-else section .right h1[data-v-4535ea24] {
        font-size: x-large;
        line-height: 2em;
}
.third-else section .right .data[data-v-4535ea24] {
        padding-top: 3em;
}
.third-else section .right .data .item[data-v-4535ea24] {
          width: 80%;
          margin: 2em auto;
}
.third-else section .right .data .item .box[data-v-4535ea24] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
}
.third-else section .right .data .item .box p[data-v-4535ea24] {
              width: 50%;
              text-align: center;
              line-height: 3em;
              height: 3em;
              overflow: hidden;
              text-overflow: ellipsis;
}
