.second-else[data-v-62ffd4fd] {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: KaiTi;
}
.second-else h1[data-v-62ffd4fd] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3em;
    font-size: xx-large;
    font-weight: 900;
    color: #2babff;
    text-align: center;
}
.second-else .top[data-v-62ffd4fd] {
    margin-top: 10%;
    width: 100%;
    height: 38%;
}
.second-else .top .box[data-v-62ffd4fd] {
      width: 100%;
      height: 100%;
      position: relative;
}
.second-else .top .box h1[data-v-62ffd4fd] {
        line-height: 3em;
}
.second-else .top .box .data[data-v-62ffd4fd] {
        padding-top: 4em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
            justify-content: space-around;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.second-else .top .box .data .item[data-v-62ffd4fd] {
          width: 40%;
          height: 3em;
          font-size: larger;
}
.second-else .top .box .data .item .block[data-v-62ffd4fd] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: distribute;
                justify-content: space-around;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            line-height: 3em;
            text-align: center;
}
.second-else .top .box .data .item .block .tips[data-v-62ffd4fd] {
              width: 50%;
              text-align: center;
}
.second-else .top .box .data .item .block .value[data-v-62ffd4fd] {
              width: 50%;
              text-align: center;
}
.second-else .bottom[data-v-62ffd4fd] {
    width: 100%;
    height: 48%;
}
.second-else .bottom .box[data-v-62ffd4fd] {
      width: 100%;
      height: 100%;
      position: relative;
}
.second-else .bottom .box h1[data-v-62ffd4fd] {
        line-height: 3em;
}
.second-else .bottom .box .data[data-v-62ffd4fd] {
        padding-top: 4em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
            justify-content: space-around;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.second-else .bottom .box .data .item[data-v-62ffd4fd] {
          width: 40%;
          height: 3em;
          margin-bottom: 2em;
          font-size: larger;
}
.second-else .bottom .box .data .item .block[data-v-62ffd4fd] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: distribute;
                justify-content: space-around;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            line-height: 3em;
            text-align: center;
}
.second-else .bottom .box .data .item .block .tips[data-v-62ffd4fd] {
              width: 50%;
              text-align: center;
}
.second-else .bottom .box .data .item .block .value[data-v-62ffd4fd] {
              width: 50%;
              text-align: center;
}
