.tips[data-v-c27c44f8] {
  width: 100%;
  height: 100%;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
}
.tips p[data-v-c27c44f8] {
    background: radial-gradient(#021256, black);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: relative;
}
.tips p .close[data-v-c27c44f8] {
      position: absolute;
      top: 1em;
      right: 1em;
}
.tips p .context[data-v-c27c44f8] {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: xx-large;
      font-family: KaiTi;
      font-weight: 900;
      color: #2babff;
}
.money[data-v-c27c44f8] {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url(../../static/img/pageBg.f9f9dcd5.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: KaiTi;
}
.money .more[data-v-c27c44f8] {
    color: orange;
    cursor: pointer;
}
.money .top[data-v-c27c44f8] {
    width: 100%;
    height: 49%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.money .top .first[data-v-c27c44f8] {
      width: 32%;
      height: 100%;
      position: relative;
}
.money .top .first .btn[data-v-c27c44f8] {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
}
.money .top .second[data-v-c27c44f8] {
      width: 32%;
      height: 100%;
      position: relative;
}
.money .top .second .btn[data-v-c27c44f8] {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
}
.money .top .third[data-v-c27c44f8] {
      width: 32%;
      height: 100%;
      position: relative;
}
.money .top .third .btn[data-v-c27c44f8] {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
}
.money .bottom[data-v-c27c44f8] {
    width: 100%;
    height: 49%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.money .bottom .first[data-v-c27c44f8] {
      width: 48%;
      height: 100%;
      background: radial-gradient(#021256, black);
}
.money .bottom .first h1[data-v-c27c44f8] {
        font-size: xx-large;
        line-height: 2.5em;
        padding-left: 2em;
        font-family: KaiTi;
}
.money .bottom .first section[data-v-c27c44f8] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        width: 100%;
        height: 100%;
}
.money .bottom .first section .thing[data-v-c27c44f8] {
          width: 66%;
          height: 100%;
}
.money .bottom .first section .meeting[data-v-c27c44f8] {
          width: 33%;
          height: 100%;
}
.money .bottom .first section .meeting .data h1[data-v-c27c44f8] {
            font-size: x-large;
            padding-left: 10px;
}
.money .bottom .first section .meeting .data .join[data-v-c27c44f8],
          .money .bottom .first section .meeting .data .service[data-v-c27c44f8] {
            margin-top: 1em;
}
.money .bottom .first section .meeting .data .join h1[data-v-c27c44f8],
            .money .bottom .first section .meeting .data .service h1[data-v-c27c44f8] {
              font-size: large;
}
.money .bottom .first section .meeting .data .join p[data-v-c27c44f8],
            .money .bottom .first section .meeting .data .service p[data-v-c27c44f8] {
              padding: 1em 2em 0;
}
.money .bottom .third[data-v-c27c44f8] {
      width: 48%;
      height: 100%;
}
.money .bottom .third h1[data-v-c27c44f8] {
        font-size: xx-large;
        font-weight: 900;
        font-family: KaiTi;
        padding: 0.5em;
        text-align: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        color: #2babff;
}
.money .bottom .third .step[data-v-c27c44f8] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
            justify-content: space-around;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.money .bottom .third .step .box[data-v-c27c44f8] {
          width: 33%;
          height: 100%;
          /*
          display: flex;
          justify-content: center;
          align-items: center; */
}
.money .bottom .third .step .box h1[data-v-c27c44f8] {
            font-size: x-large;
            font-weight: 800;
            font-family: KaiTi;
}
.money .bottom .third .step .box[data-v-c27c44f8] .el-step__main {
            border-left: 0.5em solid #7c80fb;
            margin-left: 0.5em;
}
