.miniblock[data-v-2202af41] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.miniblock h1[data-v-2202af41] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: x-large;
    font-weight: 900;
    font-family: KaiTi;
    color: #2babff;
    text-align: center;
}
.miniblock .block[data-v-2202af41] {
    width: 40%;
}
.miniblock .block .box[data-v-2202af41] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-align: center;
}
.miniblock .block .box .name[data-v-2202af41] {
        width: 50%;
        height: 5em;
        line-height: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        font-weight: 900;
        font-family: KaiTi;
}
.miniblock .block .box .num[data-v-2202af41] {
        width: 50%;
}
.miniblock .block .box .num .zb[data-v-2202af41] {
          color: orange;
          font-size: large;
          font-weight: 900;
}
